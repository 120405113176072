import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '../../../validators/form-validators';
import { enumToArray } from '@shared/utils/enum-to-array.util';
import { DropdownModel } from '@shared-features/models/dropdown-model';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  @Output() searchValue = new EventEmitter();
  @Input() placeholder: string;
  @Input() showSearchActions: boolean = true;
  @Input() searchKeyEnum: any;
  @Input() multiple = false;
  @Input() notFoundValues = [];
  @Input() pattern: string | RegExp;
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() inputType: string = 'text';
  @Input() initialValue;
  @Input() clearAllValues;
  @Input() allowSpaceAsSeparator = false;

  readonly separatorKeysCodes = [ENTER, COMMA];

  dispose$ = new Subject();
  form: UntypedFormGroup;
  searchKeyItems: DropdownModel[] = [];
  notFoundValuesSet = new Set();

  constructor() {}

  ngOnInit(): void {
    this.initForm();
    merge(
      this.form.controls.searchTerm.valueChanges,
      this.form.controls.searchTermList.valueChanges
    )
      .pipe(debounceTime(500))
      .subscribe((value) => {
        if (!this.showSearchActions) {
          if (Array.isArray(value)) {
            this.searchValue.emit(value.join(','));
          } else {
            this.searchValue.emit(value);
        }}
      });

  }

  ngOnChanges({ searchKeyEnum, notFoundValues, clearAllValues}: SimpleChanges): void {
    if (searchKeyEnum && searchKeyEnum.currentValue) {
      this.setupSearchKeyItems();
    }
    if (notFoundValues && notFoundValues.currentValue.length) {
      const notFoundValuesSet = new Set();
      this.notFoundValues.forEach((v) => notFoundValuesSet.add(v.toString()));
      this.notFoundValuesSet = notFoundValuesSet;
    }
    if(clearAllValues){
      this.onClearButtonClicked()
    }
  }

  ngOnDestroy(): void {
    this.notFoundValuesSet.clear();
    this.dispose$.next(true);
    this.dispose$.complete();
  }

  initForm() {
    this.form = new UntypedFormGroup({
      searchTerm: new UntypedFormControl(this.initialValue || '', [
        noWhitespaceValidator,
        Validators.pattern(this.pattern),
      ]),
      searchKey: new UntypedFormControl(this.searchKeyItems[0]),
      searchTermList: new UntypedFormControl(this.initialValue?.split(',') || [])
    });
  }

  onClearButtonClicked() {
    this.form?.controls['searchTerm'].setValue(null);
    this.form?.controls['searchTermList'].setValue([]);

    if(!this.showSearchActions){
      return
    }

    if (this.searchKeyItems.length) {
      this.searchValue.emit({
        searchTerm: this.form.controls['searchTerm'].value,
        searchKey: null,
      });
    } else {
      this.searchValue.emit(this.form.controls['searchTerm'].value);
    }
  }

  onSearchButtonClicked() {
    this.notFoundValues = [];

    if (this.searchKeyItems.length) {
      this.searchValue.emit({
        searchTerm: this.multiple
          ? this.form.controls['searchTermList'].value?.join(',')
          : this.form.controls['searchTerm'].value,
        searchKey: this.form.controls['searchKey'].value.dropDownValue,
      });
    } else {
      this.searchValue.emit(
        this.multiple
          ? this.form.controls['searchTermList'].value?.join(',')
          : this.form.controls['searchTerm'].value
      );
    }
  }

  setupSearchKeyItems() {
    this.searchKeyItems = enumToArray(this.searchKeyEnum).map(
      (item: any) =>
        ({
          dropDownDisplayName: item.key,
          dropDownValue: item.value,
        } as DropdownModel)
    );
  }

  removeSearch(searchTerm: string): void {
    this.form.controls.searchTermList.setValue(
      this.form.value.searchTermList?.filter((e) => e !== searchTerm)
    );
  }

  addSearchTerm(event: MatChipInputEvent, input: HTMLInputElement): void {
    const inputValue = event.value.trim();

    const separatorRegex = this.allowSpaceAsSeparator ? /[\s,]+/ : /[,]+/;

    const value = inputValue.split(separatorRegex);

    const valueMap = value.reduce((group, word) => {
      group[word.toLowerCase()] = true;
      return group;
    }, {});

    if (inputValue) {
      this.form.controls.searchTermList.setValue(
        this.form.value.searchTermList
          .filter((v) => !valueMap[v.toLowerCase()])
          .concat(value)
      );
      input.value = '';
    }
  }
}
