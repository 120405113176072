import { ShipmentsListType } from '@features/shipments/enums/shipments-list-type.enum';
import { RouteConfig } from '../models/route-config';
import { DateRangeType } from '@shared/enums/date-range-type.enum';
import { TripPlanningMode } from '@features/trip-planning/enums';

export class RoutesUtil {
  static Dashboard = new RouteConfig('dashboard');

  // shipments
  static Shipments = new RouteConfig('shipments');
  static ShipmentsB2b = new RouteConfig('b2b', RoutesUtil.Shipments);
  static B2bShipmentsList = new RouteConfig<{ type: ShipmentsListType }>(
    ':type',
    RoutesUtil.ShipmentsB2b
  );

  static ShipmentsViewWithoutRange = new RouteConfig<{ id: number; type: ShipmentsListType }>(
    ':type/view/:id',
    RoutesUtil.ShipmentsB2b
  );

  static ShipmentsView = new RouteConfig<{
    id: number;
    dateRange: DateRangeType;
    type: ShipmentsListType;
  }>(':type/view/:id/:dateRange', RoutesUtil.ShipmentsB2b);

  static ShipmentTemplate = new RouteConfig('shipments/template');
  static ShipmentTemplateCementAdd = new RouteConfig<void, { redirect?: string }>(
    'cement/add',
    RoutesUtil.ShipmentTemplate
  );
  static ShipmentTemplateCementEdit = new RouteConfig<
    { id: number; type: ShipmentsListType },
    { redirect?: string }
  >(':type/cement/:id', RoutesUtil.ShipmentTemplate);
  static ShipmentTemplateBasicAdd = new RouteConfig<void, { redirect?: string }>(
    'main/add',
    RoutesUtil.ShipmentTemplate
  );
  static ShipmentTemplateBasicEdit = new RouteConfig<
    { id: number; type: ShipmentsListType },
    { redirect?: string }
  >(':type/main/:id', RoutesUtil.ShipmentTemplate);

  static ShipmentTemplateOrdersAdd = new RouteConfig<void, { redirect?: string }>(
    'orders/add',
    RoutesUtil.ShipmentTemplate
  );

  static ShipmentsB2c = new RouteConfig('b2c', RoutesUtil.Shipments);
  static ShipmentsB2cView = new RouteConfig<{ id: number; dateRange: DateRangeType }>(
    'view/:id/:dateRange',
    RoutesUtil.ShipmentsB2c
  );
  static ShipmentsB2cViewWithoutRange = new RouteConfig<{ id: number }>(
    'view/:id',
    RoutesUtil.ShipmentsB2c
  );

  // live tracking
  static LiveTracking = new RouteConfig<{ id: number }>('live-tracking/b2b');
  static LiveTrackingShipment = new RouteConfig<{ id: number }>(':id', RoutesUtil.LiveTracking);

  static B2cLiveTracking = new RouteConfig<{ id: number }>('live-tracking/b2c');
  static B2cLiveTrackingShipment = new RouteConfig<{ id: number }>(
    ':id',
    RoutesUtil.B2cLiveTracking
  );

  // customers
  static Customers = new RouteConfig('customers');
  static CustomersB2B = new RouteConfig('b2b', RoutesUtil.Customers);

  static CustomersAdd = new RouteConfig('add', RoutesUtil.CustomersB2B);
  static CustomersVerify = new RouteConfig<{ id: number }>(
    'my-customers/verify/:id',
    RoutesUtil.CustomersB2B
  );
  static CustomersVerifyWithoutId = new RouteConfig('verify', RoutesUtil.CustomersB2B);

  static MyCustomersAdd = new RouteConfig<{ id: number }>(
    'my-customers/add/:id',
    RoutesUtil.CustomersB2B
  );
  static CustomersB2BView = new RouteConfig<
    { id: number },
    { tabIndex?: number; tabGroup?: string }
  >('view/:id', RoutesUtil.CustomersB2B);

  static CustomersViewWithTab = new RouteConfig<{ id: number; tabIndex: number }>(
    'view/:id/:tabIndex',
    RoutesUtil.CustomersB2B
  );
  static CustomersUsersAdd = new RouteConfig<{ id: number }>(
    ':id/users/add',
    RoutesUtil.CustomersB2B
  );
  static CustomersUsersView = new RouteConfig<{ customerId: number; userId: number }>(
    ':customerId/users/view/:userId',
    RoutesUtil.CustomersB2B
  );
  static CustomersReceiversAdd = new RouteConfig<{ id: number }>(
    ':id/receivers/add',
    RoutesUtil.CustomersB2B
  );
  static CustomersReceiversView = new RouteConfig<{ customerId: number; receiverId: number }>(
    ':customerId/receivers/view/:receiverId',
    RoutesUtil.CustomersB2B
  );

  static CustomersContractsAdd = new RouteConfig<{ type: string; customerId: number }>(
    ':customerId/contracts/:type/add',
    RoutesUtil.CustomersB2B
  );
  static CustomersContractsEdit = new RouteConfig<{
    type: string;
    contractId: number;
    customerId: number;
  }>(':customerId/contracts/:type/edit/:contractId', RoutesUtil.CustomersB2B);
  static CustomersContractsClone = new RouteConfig<{
    type: string;
    contractId: number;
    customerId: number;
  }>(':customerId/contracts/:type/clone/:contractId', RoutesUtil.CustomersB2B);

  static CustomersTradeLanesAdd = new RouteConfig<{ customerId: number; contractId: number }>(
    ':customerId/contracts/:contractId/trade-lane/add',
    RoutesUtil.CustomersB2B
  );
  static CustomersTradeLanesEdit = new RouteConfig<{
    customerId: number;
    contractId: number;
    tradeLaneId: number;
  }>(':customerId/contracts/:contractId/trade-lane/edit/:tradeLaneId', RoutesUtil.CustomersB2B);

  static CustomersFleetsContractDetails = new RouteConfig<{
    id: number;
    name: string;
    fleetName: string;
    contractId: number;
  }>(':id/:name/:fleetName/:contractId/customer-fleet-contract/details', RoutesUtil.CustomersB2B);

  static GlobalSpecificContractDetails = new RouteConfig<{
    id: number;
    name: string;
    fleetName: string;
    contractId: number;
  }>(':id/:name/:fleetName/:contractId/global-specific-contract/details', RoutesUtil.CustomersB2B);

  // fleet
  static Fleets = new RouteConfig('fleets');
  static FleetsAdd = new RouteConfig('add', RoutesUtil.Fleets);
  static FleetsView = new RouteConfig<{ id: number }, { tabIndex?: number; tabGroup?: string }>(
    'view/:id',
    RoutesUtil.Fleets
  );
  static FleetsAddBulkDrivers = new RouteConfig<{ id: number }>(
    ':id/add-bulk-drivers',
    RoutesUtil.Fleets
  );
  static FleetsAddBulkTrucks = new RouteConfig<{ id: number }>(
    ':id/add-bulk-trucks',
    RoutesUtil.Fleets
  );
  static FleetsAddBulkDriversTrucks = new RouteConfig<{ id: number }>(
    ':id/add-bulk-drivers-with-trucks',
    RoutesUtil.Fleets
  );
  static FleetsRequest = new RouteConfig<{ id: number }>('requests/:id', RoutesUtil.Fleets);
  static FleetsMadar = new RouteConfig<{ id: number }>('madar-providers', RoutesUtil.Fleets);
  static FleetsUsersAdd = new RouteConfig<{ id: number }>(':id/users/add', RoutesUtil.Fleets);
  static FleetsUsersView = new RouteConfig<{ fleetId: number; userId: number }>(
    ':fleetId/users/:userId',
    RoutesUtil.Fleets
  );
  static FleetsDriversAdd = new RouteConfig<{ fleetName: string; id: number }>(
    ':fleetName/:id/drivers/add',
    RoutesUtil.Fleets
  );
  // static FleetsDriversAddAsLead = new RouteConfig<{ fleetId: number, userId: number, leadMobileNumber }>(
  //   ':fleetName/:id/drivers/add/:leadMobileNumber', RoutesUtil.Fleets);
  static FleetsDriversView = new RouteConfig<{ id: number }>('drivers/:id', RoutesUtil.Fleets);
  static FleetsTrucksAdd = new RouteConfig<{ fleetName: string; id: number }>(
    ':fleetName/:id/trucks/add',
    RoutesUtil.Fleets
  );
  static FleetsTrucksView = new RouteConfig<{ id: number }>('trucks/:id', RoutesUtil.Fleets);
  static FleetsTruckOperationReport = new RouteConfig<{ id: number }>(
    'trucks/:id/operation-report',
    RoutesUtil.Fleets
  );
  static FleetsTrailersAdd = new RouteConfig<{ fleetName: string; fleetId: number }>(
    ':fleetName/:fleetId/trailers/add',
    RoutesUtil.Fleets
  );
  static FleetsTrailersEdit = new RouteConfig<{ id: number }>(
    'trailers/edit/:id',
    RoutesUtil.Fleets
  );
  static FleetsTrailersView = new RouteConfig<{ id: number }>('trailers/:id', RoutesUtil.Fleets);

  static FleetsGlobalSpecificContractDetails = new RouteConfig<{
    id: number;
    name: string;
    fleetName: string;
    contractId: number;
  }>(':id/:name/:fleetName/:contractId/global-specific-contract/details', RoutesUtil.Fleets);

  static FleetsContractsAdd = new RouteConfig<{ type: string; fleetId: number }>(
    ':fleetId/contracts/:type/add',
    RoutesUtil.Fleets
  );
  static FleetsContractsEdit = new RouteConfig<{
    type: string;
    contractId: number;
    fleetId: number;
  }>(':fleetId/contracts/:type/edit/:contractId', RoutesUtil.Fleets);

  static FleetsContractsClone = new RouteConfig<{
    type: string;
    contractId: number;
    fleetId: number;
  }>(':fleetId/contracts/:type/clone/:contractId', RoutesUtil.Fleets);

  static FleetsTradeLanesAdd = new RouteConfig<{ fleetId: number; contractId: number }>(
    ':fleetId/contracts/:contractId/trade-lane/add',
    RoutesUtil.Fleets
  );
  static FleetsTradeLanesEdit = new RouteConfig<{
    fleetId: number;
    contractId: number;
    tradeLaneId: number;
  }>(':fleetId/contracts/:contractId/trade-lane/edit/:tradeLaneId', RoutesUtil.Fleets);

  // FleetsB2c
  static FleetsB2c = new RouteConfig('FleetsB2c');
  static FleetsB2cAdd = new RouteConfig('add', RoutesUtil.FleetsB2c);
  static FleetsB2cView = new RouteConfig<{ id: number }>('view/:id', RoutesUtil.FleetsB2c);
  static FleetsB2cRequest = new RouteConfig<{ id: number }>('requests/:id', RoutesUtil.FleetsB2c);
  static FleetsB2cUsersView = new RouteConfig<{ FleetsB2cId: number; userId: number }>(
    ':FleetsB2cId/users/:userId',
    RoutesUtil.FleetsB2c
  );
  static FleetsB2cUsersAdd = new RouteConfig<{ id: number }>(':id/users/add', RoutesUtil.FleetsB2c);
  static FleetsB2cDriversAdd = new RouteConfig<{ FleetsB2cName: string; id: number }>(
    ':FleetsB2cName/:id/drivers/add',
    RoutesUtil.FleetsB2c
  );
  static FleetsB2cDriversView = new RouteConfig<{ id: number }>(
    'drivers/:id',
    RoutesUtil.FleetsB2c
  );
  static FleetsB2cTrucksAdd = new RouteConfig<{ FleetsB2cName: string; id: number }>(
    ':FleetsB2cName/:id/trucks/add',
    RoutesUtil.FleetsB2c
  );
  static FleetsB2cTrucksView = new RouteConfig<{ id: number }>('trucks/:id', RoutesUtil.FleetsB2c);

  // Reports
  static Reports = new RouteConfig('reports');
  static ReportsConsolidation = new RouteConfig('consolidation', RoutesUtil.Reports);
  static ReportsDrivers = new RouteConfig('drivers', RoutesUtil.Reports);
  static ReportsTrucks = new RouteConfig('trucks', RoutesUtil.Reports);
  static ReportsWasl = new RouteConfig('wasl', RoutesUtil.Reports);
  static ReportsAVL = new RouteConfig('avl', RoutesUtil.Reports);
  static ReportsCement = new RouteConfig('cement', RoutesUtil.Reports);
  static ReportsShipments = new RouteConfig('shipments', RoutesUtil.Reports);
  static ReportsShipmentInvoices = new RouteConfig('shipment-invoices', RoutesUtil.Reports);
  static ReportsG2G = new RouteConfig('G2G', RoutesUtil.Reports);
  static ReportsContract = new RouteConfig('contract', RoutesUtil.Reports);
  static ReportsG2gIntegration = new RouteConfig('g2g-integration', RoutesUtil.Reports);
  static ReportsTruckUtilization = new RouteConfig('truck-utilization', RoutesUtil.Reports);

  // Users
  static Users = new RouteConfig('users');
  static UsersAdd = new RouteConfig('add', RoutesUtil.Users);
  static UsersViewEdit = new RouteConfig<{ userId: number }>(':userId', RoutesUtil.Users);

  // invoices
  static Invoices = new RouteConfig('invoices');
  static InvoicesCustomer = new RouteConfig('customer', RoutesUtil.Invoices);
  static InvoicesFleet = new RouteConfig('fleet', RoutesUtil.Invoices);
  static InvoiceFleetDetailsView = new RouteConfig<{ fleetId: number; fleetName: string }>(
    'fleet/:fleetId/:fleetName',
    RoutesUtil.Invoices
  );
  static InvoicesList = new RouteConfig<{ customerId: number; customerName: string }>(
    'customer/:customerId/:customerName',
    RoutesUtil.Invoices
  );
  static InvoicesCustomerCreate = new RouteConfig('customer/create', RoutesUtil.Invoices);

  static InvoicesCustomerDetails = new RouteConfig<{
    invoiceId: number;
    customerId: number;
    customerName: string;
  }>(':customerId/:customerName/delivery-notes/:invoiceId', RoutesUtil.Invoices);

  static InvoicesEdit = new RouteConfig<{
    invoiceId: number;
    customerId: number;
    customerName: string;
  }>(':customerId/:customerName/edit-invoice/:invoiceId', RoutesUtil.Invoices);

  static InvoicesFleetCreate = new RouteConfig('fleet/create', RoutesUtil.Invoices);
  static InvoicesFleetDetails = new RouteConfig<{ id: number; name: string }>(
    'invoice/:id/:name',
    RoutesUtil.Invoices
  );

  // delivery notes
  static DeliveryNotes = new RouteConfig('delivery-notes');

  // Receivers
  static Receivers = new RouteConfig('receivers');
  static ReceiversAdd = new RouteConfig('add', RoutesUtil.Receivers);
  static ReceiversView = new RouteConfig<{ receiverId: number }>(
    'view/:receiverId',
    RoutesUtil.Receivers
  );

  // Lead
  static Leads = new RouteConfig('leads');
  static LeadsDriversAdd = new RouteConfig<{
    fleetName: string;
    id: number;
    leadMobileNumber: number;
  }>(':fleetName/:id/:leadMobileNumber', RoutesUtil.Leads);

  static TripPerformance = new RouteConfig('trip-performance');
  static TripPerformanceAdd = new RouteConfig('add', RoutesUtil.TripPerformance);
  static TripPerformanceEdit = new RouteConfig<{ id: number }>(
    'edit/:id',
    RoutesUtil.TripPerformance
  );

  // Organization
  static Organizations = new RouteConfig('organizations');
  static OrganizationsAdd = new RouteConfig('add', RoutesUtil.Organizations);
  static OrganizationsView = new RouteConfig<{ id: number }>('view/:id', RoutesUtil.Organizations);

  static OrganizationsUsersAdd = new RouteConfig<{ id: number }>(
    ':id/users/add',
    RoutesUtil.Organizations
  );

  static OrganizationsUsersView = new RouteConfig<{ organizationId: number; userId: number }>(
    ':organizationId/users/view/:userId',
    RoutesUtil.Organizations
  );

  // Settings
  static Settings = new RouteConfig('settings');

  // Transportation Payments
  static TransPays = new RouteConfig('transportation-payments');

  static TransPaysShipments = new RouteConfig('shipments', RoutesUtil.TransPays);

  static TransPaysPayments = new RouteConfig('payments', RoutesUtil.TransPays);
  static TransPaysPaymentsView = new RouteConfig<{ invoiceId: number }>(
    'payments/view/:invoiceId',
    RoutesUtil.TransPays
  );
  static TransPaysPaymentsViewWithFleet = new RouteConfig<{
    fleetId: number;
    fleetName: string;
    invoiceId: number;
  }>(':fleetName/:fleetId/payments/view/:invoiceId', RoutesUtil.TransPays);

  static Auth = new RouteConfig('auth');
  static AuthLogin = new RouteConfig('login', RoutesUtil.Auth);
  static AuthVerifyEmail = new RouteConfig<{ token: string }>(
    'verify-email/:token',
    RoutesUtil.Auth
  );
  static AuthResetPassword = new RouteConfig<{ token: string }>(
    'reset-password/:token',
    RoutesUtil.Auth
  );
  static AuthResetPasswordBy = new RouteConfig('reset-password-by', RoutesUtil.Auth);
  static AuthOTP = new RouteConfig<{ mobileNumber: string }>('otp/:mobileNumber', RoutesUtil.Auth);

  static Home = RoutesUtil.Dashboard;

  // Rules
  static Rules = new RouteConfig('rules');
  static RulesB2C = new RouteConfig('b2c', RoutesUtil.Rules);
  static RulesAdd = new RouteConfig('add', RoutesUtil.RulesB2C);
  static RulesEdit = new RouteConfig<{ id: number }>('edit/:id', RoutesUtil.RulesB2C);
  static RulesB2CView = new RouteConfig<{ id: number }>('view/:id', RoutesUtil.RulesB2C);

  // Configuration
  static Configuration = new RouteConfig('configuration');
  static ConfigurationB2c = new RouteConfig('b2c', RoutesUtil.Configuration);

  static Logs = new RouteConfig('logs');
  static LogsShipmentRequests = new RouteConfig('b2c/shipment-requests', RoutesUtil.Logs);
  static LogsExceptions = new RouteConfig('exceptions', RoutesUtil.Logs);
  static LogsExceptionsDetails = new RouteConfig<{ id: number }>(
    'exceptions/view/:id',
    RoutesUtil.Logs
  );

  // sellers
  static Sellers = new RouteConfig('sellers');
  static SellersView = new RouteConfig<{ id: number }>('view/:id', RoutesUtil.Sellers);

  //zones
  static Zones = new RouteConfig('zones');
  static ZonesB2C = new RouteConfig('b2c', RoutesUtil.Zones);

  // trip planning
  static TripPlanning = new RouteConfig('trip-planning');
  static TripPlanningRegularTrucks = new RouteConfig<{
    id: number;
    name: string;
    mode: TripPlanningMode.Regular;
  }>('trucks/:mode/:id/:name', RoutesUtil.TripPlanning);
  static TripPlanningRentalTrucks = new RouteConfig<{ mode: TripPlanningMode.Rental }>(
    'trucks/:mode',
    RoutesUtil.TripPlanning
  );

  // rental profit
  static RentalProfit = new RouteConfig('rental-profit');

  // Support
  static Support = new RouteConfig('customer-support');
  static SupportAddTicket = new RouteConfig('add-ticket', RoutesUtil.Support);
  static SupportTickets = new RouteConfig('tickets', RoutesUtil.Support);

  // Smart Center
  static SmartCenter = new RouteConfig('smart-center');

  // Sales Orders
  static Orders = new RouteConfig('orders');

  static SalesOrders = new RouteConfig('sales-orders');
  static SalesOrdersAdd = new RouteConfig('add', RoutesUtil.SalesOrders);

  // release notes
  static ReleaseNotes = new RouteConfig('release-notes');
  static ReleaseNotesAdd = new RouteConfig('add', RoutesUtil.ReleaseNotes);
  static ReleaseNoteView = new RouteConfig<{ id: number }>('view/:id', RoutesUtil.ReleaseNotes);
  static ReleaseNoteEdit = new RouteConfig<{ id: number }>('edit/:id', RoutesUtil.ReleaseNotes);

  static Payment = new RouteConfig('payment');
  static PaymentStatus = new RouteConfig('status', RoutesUtil.Payment);
}
